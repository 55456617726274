<template>
	<div id="textbook">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教材管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="tabTap(index)" :class="index == tabIndex ? 'active' : ''" v-for="(item, index) in meun" :key="index">{{ item }}</div>
				</div>
			</div>
			<div class="content">
				<div class="suguan">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item">
							<el-button size="small" @click="openDialog(1)">添加订单</el-button>
							<el-button size="small" type="primary" @click="derive">导出订单</el-button>
						</div>
					</div>
					<el-table :data="list" height="65vh" style="width: 100%;border: 1px solid #EEEEEE;">
						<el-table-column type="index" label="序号" align="center"></el-table-column>
						<el-table-column label="订单编号" align="center" prop="order_sn"></el-table-column>
						<el-table-column label="订单标题" align="center" prop="title"></el-table-column>
						<el-table-column label="学年" align="center" prop="year_name"></el-table-column>
						<el-table-column prop="semester_name" label="学期" align="center"></el-table-column>
						<el-table-column prop="money" label="订单金额" align="center"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="lookDetail(scope.row.id)" type="text" size="small">查看</el-button>
								<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</index>

		<!-- 添加 编辑教材-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						订单标题
					</div>
					<el-input v-model="title" placeholder="请输入订单标题" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						学年
					</div>
					<el-select v-model="yearval" placeholder="请选择学年" style="width: 70%;" filterable @change="yearChange">
						<el-option v-for="item in years" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						学期
					</div>
					<el-select v-model="semester_id" placeholder="请选择学期" style="width: 70%;" filterable>
						<el-option v-for="item in xueqiList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="padding-top: 10px;">
						<span>*</span>
						教材
					</div>
					<el-select v-model="Book_id" placeholder="请选择教材" style="width: 70%;" filterable multiple @change="bookchange">
						<el-option v-for="item in Booklist" :key="item.id" :label="item.name" :value="item.id">
							<span style="float: left">{{ item.name }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">数量{{ item.number }}</span>
						</el-option>
					</el-select>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 订单详情 -->
		<el-dialog :title="info.title" :visible.sync="orderShow" width="600px" :before-close="handleClose">
			<div class="edit-box" style="max-height: 580px;overflow-y: auto;">
				<div class="u-f-item u-f-jsb" style="padding: 10px;">
					<div>学年：{{info.year_name}}</div>
					<div>学期：{{info.semester_name}}</div>
				</div>
				<el-table :data="info.detail" style="width: 100%;" border>
					<el-table-column prop="id" label="ID" align="center" width="80"></el-table-column>
					<el-table-column prop="name" label="教材" align="center"></el-table-column>
					<el-table-column prop="number" label="数量" align="center"></el-table-column>
					<el-table-column prop="price" label="单价" align="center"></el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="u-f-right u-font-blod" style="font-weight: bold;">
				总价：{{info.money}}
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meun: ['教材管理', '订单管理'],
			tabIndex: 1,
			page: 1,
			limit: 10,
			list: [],
			total: 0,
			VisibleTitle: '添加订单',
			addVisible: false,
			title: '',
			publishing: '',
			number: 1,
			editId: '',
			type: 1,
			years: [],
			xueqiList: [],
			semester_id: '',
			yearval: '',
			Booklist: [],
			Book_id: [],
			detail: [],
			info: '',
			orderShow: false
		};
	},
	mounted() {
		this.getYearList();
		this.getOrderlist();
		this.getTextBookList();
	},
	methods: {
		lookDetail(id) {
			this.$api.setting.OrderDetail({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
					this.orderShow = true;
				}
			});
		},
		bookchange() {
			let arr = [];
			for (let i in this.Booklist) {
				for (let j in this.Book_id) {
					if (this.Booklist[i].id == this.Book_id[j]) {
						arr.push({
							id: this.Booklist[i].id,
							number: this.Booklist[i].number,
							name: this.Booklist[i].name
						});
					}
				}
			}
			this.detail = arr;
		},
		getTextBookList() {
			this.$api.setting.getTextBookList({}).then(res => {
				if (res.data.code == 1) {
					this.Booklist = res.data.data.rows;
				}
			});
		},
		yearChange() {
			this.semester_id = '';
			this.getXueqiList(1);
		},
		getXueqiList(type) {
			this.$api.setting
				.getXueQiList({
					year_id: this.yearval
				})
				.then(res => {
					if (res.data.code == 1) {
						this.xueqiList = res.data.data;
					}
				});
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.years = res.data.data;
					if (res.data.data.length) {
						if (this.years.filter(item => item.is_default == 2).length > 0) {
							this.yearval = this.years.filter(item => item.is_default == 2)[0].id;
						} else {
							this.yearval = this.years[0].id;
						}
						this.getXueqiList();
					} else {
						this.yearval = '';
					}
				}
			});
		},
		tabTap(index) {
			if (index == 0) {
				this.$router.push('/textbook');
			}
			this.tabIndex = index;
		},
		getOrderlist() {
			this.$api.setting.getOrderlist({ page: this.page, limit: this.limit }).then(res => {
				if (res.data.code == 1) {
					this.total = res.data.data.total;
					this.list = res.data.data.rows;
				}
			});
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getOrderlist();
		},
		closeVisible() {
			this.init();
			this.addVisible = false;
		},
		init() {
			this.title = '';
			this.yearval = '';
			this.semester_id = '';
			this.Book_id = [];
			this.detail = [];
		},
		handleClose(done) {
			done();
		},
		openDialog(type, item) {
			this.type = type;
			if (type == 1) {
				this.VisibleTitle = '添加订单';
			} else if (type == 2) {
				this.VisibleTitle = '编辑订单';
				this.title = item.title;
				this.yearval = item.year_id;
				this.getXueqiList();
				this.semester_id = item.semester_id;
				if (item.teaching_material_ids) {
					let arr = [];
					let arrs = item.teaching_material_ids.split(',');
					for (let i in arrs) {
						arr.push(Number(arrs[i]));
					}
					this.Book_id = arr;
				}
				this.editId = item.id;
				this.bookchange();
			}
			this.addVisible = true;
		},
		submit() {
			if (this.type == 1) {
				this.addTextBook();
			} else if (this.type == 2) {
				this.editTextBook();
			}
		},
		// 添加
		addTextBook() {
			if (!this.title) {
				return this.$message.error('请输入教材名称');
			}
			if (!this.yearval) {
				return this.$message.error('请选择学年');
			}
			if (!this.semester_id) {
				return this.$message.error('请选择学期');
			}
			if (!this.detail.length) {
				return this.$message.error('请选择教材');
			}
			this.$api.setting
				.addOrder({
					title: this.title,
					year_id: this.yearval,
					semester_id: this.semester_id,
					detail: this.detail
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.getOrderlist();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑
		editTextBook() {
			if (!this.title) {
				return this.$message.error('请输入订单标题');
			}
			if (!this.yearval) {
				return this.$message.error('请选择学年');
			}
			if (!this.semester_id) {
				return this.$message.error('请选择学期');
			}
			if (!this.detail.length) {
				return this.$message.error('请选择教材');
			}
			this.$api.setting
				.editOrder({
					title: this.title,
					year_id: this.yearval,
					semester_id: this.semester_id,
					detail: this.detail,
					id: this.editId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('编辑成功');
						this.init();
						this.getOrderlist();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 导出
		derive() {
			this.$api.setting.deriveOrder({}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('导出成功');
					location.href = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
#textbook {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan {
			padding: 15px 50px 15px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					width: 80px;
					text-align: right;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
